<template lang="pug">
TableV2(
  :isViewAdd="false"
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
).pa-4
  FormAdd(slot="AddBlock" actionType="add")

</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'SailorRecordBookTable',
  components: {
    FormAdd: () => import('./Form.vue')
  },
  data () {
    return {
      headers: [
        { value: 'name_book', text: this.$t('number'), sortable: false },
        { value: 'date_issued', text: this.$t('dateIssue'), sortable: false },
        { value: 'blank_strict_report', text: this.$t('strictBlank'), sortable: false },
        { value: 'branch_office', text: this.$t('affiliate'), sortable: false },
        { value: 'status_document', text: this.$t('status'), sortable: false },
        { value: 'event', text: this.$t('actions'), class: 'mw-0 position-relative', sortable: false }
      ],
      actions: [
        {
          id: 1,
          action: (item) => this.$router.push({ name: 'experience-records-info', params: { id: this.$route.params.id, documentID: item.id }, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.sailor.serviceRecordBook,
      isLoading: state => state.sailor.isLoading
    })
  },
  methods: {
    ...mapActions(['getRecordBooks']),
    async getData (params) {
      await this.getRecordBooks({ ...this.$route.params, params })
    }
  }
}
</script>
